// 引入
@import "./_include/_include.sass";

#idx-links,#page-links {
  .kc_single_image {
    position: relative;
    a {
      display: block;
      overflow: hidden; }
    a,img {
      display: block;
      width: 100%;
      @include t(1); } }

  .kc-col-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    width: calc(100% + 30px);
    &>div {
      padding: 15px;
      width: calc(100% / 3 - 0.1px);
      @include mobile {
        width: 50%; }
      @include phone {
        width: 100%; }
      &:hover {
        img {
          transform: scale(1.1); } } } } }


#idx-links {
  p {
    width: calc(100% - 30px);
    margin: 0;
    line-height: 24px;
    padding: 20px 0;
    font-size: 24px;
    position: absolute;
    font-weight: normal;
    opacity: 1;
    left: 15px;
    bottom: 7%;
    transform: translateY(0);
    text-align: center;
    background-color: rgba(0,0,0,0.5);
    color: #59b5d4;
    pointer-events: none;
    @include mobile {
      bottom: 15px;
      font-size: 18px;
      padding: 15px 0;
      line-height: 18px; } } }

#page-links {
  p {
    text-align: center;
    padding-top: 20px;
    @include font(20px,$blue,2px); } }
