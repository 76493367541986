// 引入
@import "./_include/_include.sass";

* {
  font-family: 'Noto Sans TC','Microsoft Jhenghei', sans-serif; }

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
  font-family: 'Noto Sans TC','Microsoft Jhenghei', sans-serif; }

// bg
[data-bg] {
  display: block;
  @include size(100%,auto);
  background-size: cover;
  background-repeat: no-repeat; }

.container {
  margin: 0 auto; }

body {
  animation: fade .5s 1s both; }

#page {
  overflow: hidden; }
