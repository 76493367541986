//color
$gray: #eee;
$blue: #5ab5d4;
$black: #000;


@mixin mobile($mobile:1140px) {
  @media screen and (max-width: $mobile) {
    @content; } }

@mixin tablet($tablet:767px) {
  @media screen and (max-width: $tablet) {
    @content; } }

@mixin phone($phone:640px) {
  @media screen and (max-width: $phone) {
    @content; } }

@mixin mini($min:500px) {
  @media screen and (max-width: $min) {
    @content; } }

@mixin max($size) {
  @media screen and (max-width: $size) {
    @content; } }

@mixin min($size) {
  @media screen and (min-width: $size) {
    @content; } }

//mixin
@mixin size($w,$h:$w) {
  width: $w;
  height: $h; }

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin t($t:0.5) {
  transition-duration: #{$t}s; }

@mixin clr($c:#222) {
  color: $c; }

@mixin font($size:16px,$color:#000,$space:1.6px) {
  font-size: $size;
  color: $color;
  letter-spacing: $space;
  @content;
  @include phone {
    font-size: calc(#{$size} * 0.9);
    letter-spacing: calc(#{$space} * 0.9); }
  @include mini {
    font-size: calc(#{$size} * 0.75);
    letter-spacing: calc(#{$space} * 0.75); } }


@mixin container {
  display: block;
  padding: 0 3%;
  width: 100%;
  max-width: 100%;
  // +min(1500px)
  //   padding: 0 10%
  @include phone {
    padding: 0 20px; }
  @content; }
