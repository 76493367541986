// 引入
@import "./_include/_include.sass";

body.archive.category {
  .page-header {
    display: none; } }

.no-results.not-found {
  padding-right: 30px;
  @include tablet {
    padding-right: 0; } }

.banner {
  width: 100%;
  position: relative;
  @include container {
    margin-bottom: 30px; }
  img {
    display: block;
    width: 100%;
    @include tablet {
      height: 250px;
      object-fit: cover; } }
  h1 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 10px;
    padding: 10px 15px 10px 25px;
    position: absolute;
    left: 8%;
    bottom: 10%;
    background-color: rgba(#000,.3);
    color: #fff;
    @include tablet {
      font-size: 20px;
      letter-spacing: 5px;
      padding-left: 15px;
      padding-right: 10px; } } }

body:not(.home) {
  #content-inside.container {
    // +min(1500px)
 } }    //   max-width: 1400px

// body.archive.category
//   .list-article-thumb
//     display: none

//   #content-inside
//     +clearfix

//   #secondary,#primary
//     padding: 30px 0

//   #secondary
//     float: right
//     width: 30%
//     +tablet
//       width: 100%
//     .widget
//       background-color: #fafafa
//       padding: 30px
//       margin-bottom: 0
//     .widget-title
//       display: flex
//       align-items: center
//       margin-bottom: 20px
//       &:before
//         content: ""
//         display: block
//         margin-right: 10px
//         +size(10px)
//         min-width: 10px
//         border-radius: 50%
//         background-color: #7fc9d1
//     ul
//       li
//         border: none
//         padding: 0
//         margin-bottom: 10px
//         a
//           color: #333
//           font-size: 14px

//   #primary
//     // float: left
//     // width: 70%
//     +tablet
//       width: 100%
//       padding-top: 0
//     .list-article
//       margin-bottom: 40px
//       padding: 40px
//       border: none
//       +tablet
//         padding: 0 30px

//     .list-article-content
//       display: flex
//       flex-wrap: wrap
//       align-items: center
//       +mobile
//         display: block

//     .list-article-meta
//       margin-bottom: 0
//       width: 20%
//       +mobile
//         width: 100%
//       a
//         display: none
//       span
//         font-size: 12px
//         color: #aaa
//         letter-spacing: 3px

//     .entry-header
//       width: 80%
//       +mobile
//         width: 100%
//       .entry-title
//         a
//           +font(20px,#333,2px)

//     .entry-excerpt
//       padding-left: 20%
//       padding-top: 10px
//       width: 100%
//       +clearfix
//       +mobile
//         padding-left: 0
//       p
//         +font(16px,#888,1.6px)
//         font-weight: 300
//       a.more
//         padding: 7px 15px
//         text-decoration: none
//         text-transform: uppercase
//         margin-top: 15px
//         background-color: #7fc9d1
//         color: #fff
//         float: right
//         font-size: 14px

// .wp-pagenavi
//   display: flex
//   justify-content: center
//   span,a
//     margin: 0 5px
//   a
//     color: #7fc9d1

// body.single.single-post

//   .entry-header
//     display: flex
//     flex-direction: row-reverse
//     align-items: center
//     margin-bottom: 30px
//     +mobile
//       flex-direction: column-reverse
//     .entry-title
//       width: 85%
//       margin: 0
//       letter-spacing: 5px
//       font-size: 36px
//       +mobile
//         width: 100%
//       +tablet
//         font-size: 25px
//         letter-spacing: 2px
//         margin-top: 10px
//     .entry-meta
//       width: 15%
//       margin: 0
//       padding: 0
//       border: none
//       color: #aaa
//       +mobile
//         width: 100%
//       a
//         color: #aaa
//       &>span
//         display: block
//         &.byline
//           display: none
//         &.posted-on
//           font-size: 0
//           time
//             font-size: 14px
//             font-weight: 400


//   .entry-content
//     padding-left: 15%
//     padding-right: 15%
//     +mobile
//       padding: 0
//     p
//       font-size: 16px
//       letter-spacing: 0.8px
//       font-weight: 300

//   .post
//     padding: 30px 0
body.category,body.single-post {
  .seese-sidebar {
    display: none; }
  .seese-contentCol {
    width: 100%; } }


body.category {
  #content {
    .page-header {
      display: none; } }
  .entry-header {
    margin-bottom: 35px;
    span,a {
      display: block; }
    a {
      font-size: 22px;
      color: #707070;
      font-weight: bold;
      margin-bottom: 5px;
      &:hover {
        color: $blue; } }
    span {
      font-size: 12px;
      color: #707070; } }

  .post {
    display: flex;
    border: none;
    padding: 0;
    &:last-of-type {
      border: none; }
    @include tablet {
      display: block; }
    .list-article-thumb,.list-article-content {
      margin: 0;
      padding: 0;
      width: 50%;
      @include tablet {
        width: 100%; } }

    .list-article-thumb {
      display: block;
      @include tablet {
        margin-bottom: 30px; }
      img {
        display: block;
        width: 100%; } }

    .list-article-content {
      background: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 5vw;
      @include tablet {
        padding: 0 20px; } }

    .entry-excerpt {
      font-size: 16px;
      line-height: 30px;
      color: #707070;
      @include phone {
        font-size: 14px; } }

    a.more {
      margin-top: 40px;
      text-decoration: none;
      float: right;
      text-transform: capitalize;
      color: #707070;
      font-weight: normal;
      font-size: 12px;
      line-height: 30px;
      font-family: 'Microsoft JhengHei'!important;
      letter-spacing: 2px; } }

  .post {
    margin-bottom: 20px;
    @include tablet {
      margin-bottom: 30px; }
    &:nth-of-type(odd) {
      flex-direction: row-reverse; }
    &:last-of-type {
      margin-bottom: 0; } } }


body.single-post {

  #content {
    padding-bottom: 50px;
    // .breadcrumbs
    //   display: none
    &>div {
      // padding-top: 30px
      // +min(1500px)
      //   max-width: 1000px
      // +max(1281px)
      //   max-width: 760px
      // +tablet
      //   padding-top: 20px
      // +phone
 } }      //   padding-top: 10px

  img {
    display: block;
    width: 100%;
    max-width: 100%; }

  .entry-thumbnail {
    margin-bottom: 0; }

  .entry-title {
    padding: 0;
    text-align: left;
    color: $blue;
    font-size: 40px;
    letter-spacing: 4px;
    @include mobile {
      font-size: 30px; }
    @include phone {
      font-size: 20px; } }


  .entry-meta {
    border: none;
    text-align: left;
    font-size: 20px;
    letter-spacing: 2px;
    margin-bottom: 25px;
    padding: 0;
    font-weight: normal;
    @include tablet {
      font-size: 16px; }
    @include phone {
      font-size: 14px; } }

  .entry-content {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
    @include phone {
      font-size: 14px; } } }

.wp-pagenavi {
  padding-top: 70px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  span:not(.pages),a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    @include size(40px); }
  span.pages {
    margin: 0 20px; }
  a {
    border-color: transparent;
    color: #333;
    text-decoration: none;
    &:hover {
      background: none;
      color: $black; } }
  .nextpostslink {
    border: none; }
  span:not(.pages) {
    background-color: #D3D3D3;
    color: #fff; } }


.breadcrumbs {
  border: none;
  .container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: calc(3% + 30px);
    padding-right: calc(3% + 30px);
    &>span {
      // &:first-of-type
      //   margin-left: 30px
      a,span {
        font-weight: 300;
        color: #333;
        &:after {
          border-left-color: $gray; } } }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 3%;
      top: 0;
      width: calc(100% - 6%);
      height: 100%;
      background-color: $gray;
      // +min(1500px)
      //   width: calc(100% - 20%)
 } } }      //   left: 10%

body.page {
  #content-inside.container {
    img {
      display: block;
      width: 100%; }

    .kc_text_block {
      h2 {
        margin-bottom: 40px;
        font-weight: 400;
        @include font(30px,#1a1a1a,3px); }
      p {
        @include font(20px,#1a1a1a,2px);
        line-height: 30px;
        font-weight: 300; }
      img {
        display: block;
        margin: 20px 0;
        width: auto; } }

    .kc-wrap-columns,.kc_row_inner {
      margin-bottom: 40px;
      margin-left: -15px;
      width: calc(100% + 30px);
      @include clearfix;
      &.kc_row_inner:last-of-type {
        margin-bottom: 0; }
      .kc_text_block {
        margin-bottom: 30px;
        padding-top: 30px; }
      &>div {
        padding: 0 15px; } }

    [id^="title"] {
      padding-top: 20px;
      @include tablet {
        padding-top: 10px; }
      .kc-wrap-columns {
        margin-bottom: 30px; }
      .kc_text_block {
        margin: 0;
        padding: 0;
        h2 {
          display: flex;
          align-items: center;
          @include font(30px,#1a1a1a,3px);
          font-weight: 400;
          padding-bottom: 10px;
          margin-bottom: 0;
          border-bottom: solid 2px #e9e9e9;
          position: relative;
          img {
            display: block;
            margin: 0 15px 0 0;
            @include size(35px,auto); }
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            width: 30%;
            background-color: #57a625; } } } } } }

.kc_tabs>.kc_wrapper>.kc_tabs_nav,
.kc_tabs_nav>.ui-tabs-active>a, .kc_tabs_nav>li:hover, .kc_tabs_nav>li>a:hover,
.kc_tabs_nav~.kc_tab.ui-tabs-body-active {
  background-color: transparent; }

.kc_tabs>.kc_wrapper>.kc_tabs_nav {
  min-height: 0;
  margin: 40px 0; }

.kc_tabs_nav>li, .nav-pills>li {
  margin-right: 15px; }

.kc_tabs_nav>li>a, .nav-pills>li>a {
  padding: 10px 20px;
  line-height: normal;
  border: solid 1px $blue;
  color: $blue;
  &:hover {
    color: #fff;
    background-color: $blue; } }

.kc_tabs_nav>.ui-tabs-active, .kc_tabs_nav>.ui-tabs-active:hover, .kc_tabs_nav>.ui-tabs-active>a, .kc_tabs_nav>.ui-tabs-active>a:hover {
  background-color: $blue;
  color: #fff; }

.kc_tabs_nav~.kc_tab .kc_tab_content {
  padding: 0;
  @include clearfix; }

body.page #content-inside.container {
  #page-about {
    .kc_row_inner {
      display: flex;
      flex-wrap: wrap;
      &:nth-of-type(even) {
        flex-direction: row-reverse; } }

    .kc_text_block {
      margin-bottom: 0;
      padding-top: 30px;
      h3 {
        margin-bottom: 40px;
        font-weight: 400;
        @include font(30px,#1a1a1a,3px);
        @include phone {
          margin-bottom: 20px; } }
      p {
        @include font(20px,#1a1a1a,2px);
        line-height: 35px;
        @include tablet {
          line-height: 30px; }
        &+h4 {
          margin-top: 30px; } }
      h4 {
        @include font(25px,#1a1a1a,2.5px);
        border-bottom: solid 1px $blue;
        font-weight: 400;
        padding-bottom: 10px;
        margin-bottom: 20px;
        color: $blue; } } }

  #page-blog {
    .kc_text_block {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 15px;
      padding-left: 360px;
      position: relative;
      min-height: 250px;
      border-top: 1px dotted #999;
      border-bottom: 1px dotted #999;
      @include tablet {
        padding-left: 15px; }
      &:hover {
        background-color: #f2f2f2; }
      h3 {
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: 500;
        @include font(20px,#1a1a1a,2px);
        @include mobile {
          margin-top: 0; }
        @include tablet {
          margin-bottom: 20px; }
        br {
          display: none;
          @include tablet {
            display: block; } }
        em {
          display: inline;
          margin-left: 20px;
          font-weight: 300;
          font-style: inherit;
          @include font(14px,#1a1a1a,0px);
          @include tablet {
            display: block;
            margin-left: 0;
            margin-top: 10px; } } }
      p {
        padding-bottom: 30px;
        @include font(16px,#888,1px);
        @include tablet {
          padding-bottom: 15px; }
        a {
          color: #57a625; } }
      h5 {
        position: absolute;
        left: 15px;
        top: 15px;
        @include size(320px,calc(100% - 30px));
        @include tablet {
          margin-bottom: 20px;
          position: relative;
          top: 0;
          left: 0;
          @include size(100%,auto); }
        img {
          margin: 0;
          padding: 5px;
          border: 1px dotted #999;
          @include size(auto,100%);
          object-fit: cover;
          object-position: center center;
          @include tablet {
            @include size(100%,auto); } } } } } }

.kc_accordion_wrapper, .kc_tabs {
  margin: 0; }

.entry-footer {
  display: none; }

.contact {
  margin: 0 auto;
  .links {
    display: flex;
    margin-bottom: 30px;
    a {
      display: block;
      margin-right: 10px;
      img {
        @include size(auto!important,65px); } } }

  .input-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &>label {
      display: block;
      min-width: 160px;
      max-width: 160px;
      @include font(16px,#1a1a1a,2px);
      font-weight: 400;
      &+* {
        width: calc(100% - 160px); } }
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      resize: none;
      border: solid 1px #333;
      width: 100%;
      background-color: #fff; } }
  .btns {
    display: flex;
    justify-content: center;
    input {
      background-color: $blue;
      padding-left: 30px;
      padding-right: 30px; } } }
