// 引入
@import "./_include/_include.sass";


// container
.header-full-width .container {
  padding: 0; }

.container,
.header-full-width .container {
  @include container;
  @include clearfix; }

#content-inside {
  max-width: 100%;
  width: 100%; }

#content {
  min-height: 0;
  padding: 15px 0;
  @include t(.5); }

#primary {
  padding: 0; }

.entry-content {
  margin-bottom: 0; }

// header
.header-full-width .container {
  position: static; }

.site-header {
  box-shadow: none;
  @include mobile {
    background: $gray; } }

.site-branding {
  margin-left: 3%;
  .site-logo-div {
    padding: 20px 0;
    line-height: 60px;
    @include mobile {
      line-height: 40px; }
    a {
      line-height: normal; }
    img {
      @include size(auto,60px);
      @include mobile {
        @include size(auto,40px); } } } }

.header-right-wrapper {
  float: left;
  width: 100%; }

.onepress-menu.onepress-menu-mobile li {
  @include mobile {
    border: none; } }

.main-navigation {
  .onepress-menu {
    line-height: normal!important;
    width: 100%;
    background-color: $gray;
    display: flex;
    justify-content: space-between;
    // default
    li {
      a {
        text-decoration: none;
        font-family: inherit;
        @include font(20px,#333,2px);
        font-weight: 400; } }
    // 第一層
    &>li {
      &>a {
        display: block;
        padding: 35px 0;
        text-align: center;
        color: black; }
      &:hover>a {
        background-color: $blue;
        color: #fff; }
      // 第二層
      ul.sub-menu {
        border: none;
        box-shadow: 3px 3px 6px #eee;
        width: 100%;
        min-width: 200px;
        left: 50%;
        transform: translateX(-50%);
        &>li {
          &>a {
            border: none;
            text-align: center;
            @include font(16px,#333,1px);
            font-weight: 400;
            padding-left: 0;
            padding-right: 0;
            &:hover {
              background-color: #eee; } } } } }
    // 手機版
    @include mobile {
      &.onepress-menu-mobile {
        display: block;
        padding: 20px 0;
        background: #fff;
        // mobile default
        li {
          a {
            padding: 10px 30px;
            @include font(16px,#333,1px); } }
        // 第一層
        &>li {
          min-width: 100%;
          &>a {
 }            // display: none
          // 第二層
          ul.sub-menu {
            background-color: $gray;
            padding: 10px 0;
            left: 0;
            width: 100%;
            transform: none;
            &>li {
              background: transparent;
              &>a {
                background: transparent;
                color: #000; } } } }
        .nav-toggle-subarrow {
          padding: 10px 30px;
          @include mobile {
            border: none;
            width: 100%;
            color: #000;
            i {
              float: right; } } }
        .nav-toggle-dropdown {
          a {
            background-color: $blue;
            color: #fff; }
          .nav-toggle-subarrow {
            @include mobile {
              color: #fff; } } } } } } }

#nav-toggle {
  margin: 0;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 1000;
  @include size(35px);
  span {
    &:before,&:after {
      transform-origin: 50% 50%; } }
  &.nav-is-visible {
    background-color: rgba(#fff,.3);
    border-radius: 50%;
    span {
      &:before,&:after {
        top: 0;
        left: 0; }
      &:before {
        transform: rotate(45deg); }
      &:after {
        transform: rotate(-45deg); } } } }

// footer
.footer-widgets,.site-footer {
  background: transparent; }

.site-footer {
  // background-color: #efefef
  .container {
    max-width: 100%;
    width: 100%;
    padding: 0;
    background-image: url(/wp-content/uploads/footer.png);
    background-size: cover;
    background-position: center center; } }


#footer-widgets {
  @include container;
  .widget {
    margin: 0; }
  .footer-widget.widget_nav_menu {
    padding: 0;
    margin: 0;
    .menu {
      display: flex;
      justify-content: space-between;
      margin: 0;
      @include mobile {
        display: block; }
      // default
      li {
        a {
          display: block;
          text-decoration: none; } }
      // 第一層
      &>li {
        margin: 0;
        @include mobile {
          width: 100%; }
        // margin-right: 50px
        &.icons {
          display: none; }
        @include tablet {
          margin-right: 0;
          margin-bottom: 10px; }
        &.menu-item-has-children {
          &>a {
            @include mobile {
              display: flex;
              justify-content: space-between; }
            &:after {
              content: "\f107";
              font-family: 'FontAwesome';
              font-style: inherit;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              display: inline-block;
              text-rendering: auto;
              speak: none;
              font-weight: 400;
              display: none;
              @include mobile {
                display: block; } } }
          &.open {
            &>a:after {
              content: "\f106"; }
            .sub-menu {
              display: block; } } }
        &:last-of-type {
          margin-right: 0; }
        &>a {
          display: table;
          font-weight: bold;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: solid 2px #fff;
          @include font(16px,#fff,1.6px);
          @include mobile {
            display: flex;
            br {
              display: inline-flex; } }
          @include tablet {
            margin-bottom: 0;
            border-bottom-color: #fff; } }
        // 第二層
        ul.sub-menu {
          a {
            color: #fff; }
          @include mobile {
            display: none;
            padding: 10px 0; } } } } } }

// kim
.kc-container,
.kc_column, .kc_column_inner {
  padding: 0;
  min-height: 0; }

.kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner,
.kc_row.kc_row_inner {
  width: 100%;
  margin: 0; }

#welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  &>div {
    @include container; }

  .kc_text_block {
    text-align: center; } }


.site-content {
  border-top: none; }


// 右側購物車
.xoo-wsc-product.xoo-wsc-is-child {
  padding-top: 10px; }

.xoo-wsc-product {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  .xoo-wsc-img-col {
    align-self: start; }
  .xoo-wsc-qty-price {
    font-size: 14px;
    line-height: 20px;
    * {
      line-height: normal;
      font-size: 14px; }
    span:nth-of-type(3) {
      color: red;
      .woocommerce-Price-amount {
        color: red; } } }
  &.xoo-wsc-is-parent {
    .xoo-wsc-img-col {
      display: none; }
    .xoo-wsc-sum-col {
      padding-left: 0; }
    .xoo-wsc-qty-price {
      display: none; } }

  &.xoo-wsc-is-child {
    img {
      max-width: 100%; } } }


.onepress-menu li.icons {
  display: none; }

.icons-bar {
  display: flex;
  padding: 0;
  margin: 0;
  li {
    margin: 0;
    margin-left: 10px;
    list-style-type: none; }
  a {
    line-height: 35px;
    display: block;
    @include size(35px);
    color: transparent;
    text-indent: -999999px;
    background-size: cover;
    background-position: center cneter; } }

#masthead .icons-bar {
  position: absolute;
  right: 6%;
  top: 35px;
  @include mobile {
    display: none; }
  li {
    &.fb>a {
      background-image: url(/wp-content/uploads/fb.svg); }
    &.wechat>a {
      background-image: url(/wp-content/uploads/chat.svg); }
    &.line>a {
      background-image: url(/wp-content/uploads/line.svg); }
    &.ig>a {
      background-image: url(/wp-content/uploads/ig.svg); }
    &.mail>a {
      background-image: url(/wp-content/uploads/mail.svg); } } }

#footer-widgets .icons-bar {
  @include mobile {
    margin-bottom: 30px; }
  li {
    margin-left: 0px;
    margin-right: 20px;
    &.fb>a {
      background-image: url(/wp-content/uploads/fb2.svg); }
    &.wechat>a {
      background-image: url(/wp-content/uploads/chat2.svg); }
    &.line>a {
      background-image: url(/wp-content/uploads/line2.svg); }
    &.ig>a {
      background-image: url(/wp-content/uploads/ig2.svg); }
    &.mail>a {
      background-image: url(/wp-content/uploads/main2.svg); } } }


#footer-1 {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 60px;
  &>aside {
    margin: 0; }
  &>aside:nth-of-type(1) {
    width: 30%;
    @include mobile {
      width: 100%;
      margin-bottom: 30px; }
    a {
      display: block;
      max-width: 400px;
      width: 80%; } }
  &>aside:nth-of-type(2) {
    width: 70%;
    @include mobile {
      width: 100%; } }
  &>aside:nth-of-type(3) {
    width: 100%;
    margin-top: 30px;
    .textwidget {
      display: flex;
      align-items: center;
      @include tablet {
        display: block; } }
    p.copyright {
      margin-left: 60px;
      color: #fff;
      text-align: left;
      @include tablet {
        margin-left: 0; }

      a {
        display: inline-block;
        @include font(12px,#fff,1px);
        margin-left: 2px; } } } }
