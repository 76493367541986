$color: #333;
$active: #e5ac2e;
$button: #e5ac2e;

.navs-row {
  display: table;
  margin: 0 auto;
  padding: 30px;
  position: relative;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      color: $color;
      padding: 0 5px;
      margin: 0 3px;
      transition: all .5s;
      cursor: pointer;
      &.active,&:hover {
        background-color: $active; } } }

  span.next,span.prev {
    display: table;
    width: 0;
    height: 0;
    position: absolute;
    bottom: calc(50% - 5px);
    cursor: pointer;
    transition:  all .5s;
    border: {
      top: transparent 5px solid;
      bottom: transparent 5px solid;
      left: transparent 8px solid;
      right: transparent 8px solid; } }

  span.next {
    border-left-color: $color;
    right: 0;
    &:hover {
      border-left-color: $button; } }

  span.prev {
    border-right-color: $color;
    left: 0;
    &:hover {
      border-right-color: $button; } } }
